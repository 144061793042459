import Swiper from 'swiper/bundle';

export default class Carousel {
  constructor(element) {
    this.element = element;
    this.variant = this.element.dataset.variant;
    this.options = {
      slidesPerView: 1,
      spaceBetween: 0,
      pagination: {
        el: this.element.querySelector('.swiper-pagination'),
        type: 'bullets',
      },
      navigation: {
        nextEl: this.element.querySelector('.swiper-button-next'),
        prevEl: this.element.querySelector('.swiper-button-prev'),
      },
    };
    this.init();
  }

  init() {
    this.setOptions();
    if (this.variant == 'navExt') {
      const swiper = new Swiper(
        this.element.querySelector('.swiper'),
        this.options
      );
    } else {
      const swiper = new Swiper(this.element, this.options);
    }
  }

  setOptions() {
    if (this.variant == 'diapo') {
      this.options.breakpoints = {
        768: {
          slidesPerView: 1.5,
          loop: true,
          spaceBetween: 32,
        },
      };
      this.options.autoplay = {
        delay: 1000,
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
      };
    }
    if (this.variant == 'split') {
      this.options.breakpoints = {
        768: {
          slidesPerView: 2.5,
        },
      };
    }
    if ('autoplay' in this.element.dataset) {
      this.autoplayDelay = this.element.dataset.autoplay;
      this.options.autoplay = {
        delay: this.autoplayDelay,
        pauseOnMouseEnter: false,
        disableOnInteraction: false,
      };
    }
    if ('loop' in this.element.dataset) {
      this.options.loop = true;
    }
    if ('gap' in this.element.dataset) {
      this.gap = this.element.dataset.gap;
      this.options.spaceBetween = parseInt(this.gap);
    }
    if ('slides' in this.element.dataset) {
      this.options.slidesPerView = parseFloat(this.element.dataset.slides);
    }
    if ('effect' in this.element.dataset) {
      this.options.effect = this.element.dataset.effect;

      if (this.options.effect == 'fade') {
        this.options.crossFade = true;
      }

      if (this.options.effect == 'coverflow') {
        this.options.effect = 'coverflow';
        this.options.centeredSlides = true;
        this.options.slidesPerView = 1;
        this.options.coverflowEffect = {
          rotate: 20,
          stretch: -50,
          slideShadows: true,
        };

        this.options.breakpoints = {
          830: {
            slidesPerView: 2,
            coverflowEffect: {
              stretch: -110,
            },
          },
        };
      }
    }
  }
}
