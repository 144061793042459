export default class Tabs {
  constructor(element) {
    this.element = element;
    this.init();
  }

  init() {
    this.initBtns();
  }

  initBtns() {
    const btns = this.element.querySelectorAll('.js-tab');
    this.setActive({ target: btns[0] });

    for (let i = 0; i < btns.length; i++) {
      const btn = btns[i];
      btn.addEventListener('click', this.setActive.bind(this));
    }
  }

  setActive(event) {
    this.tabID = event.target.dataset.tabId;
    const btnsActifs = this.element.querySelectorAll('.is-active');

    for (let i = 0; i < btnsActifs.length; i++) {
      const btnActif = btnsActifs[i];
      btnActif.classList.remove('is-active');
    }
    event.target.classList.add('is-active');

    this.tabContentID = this.element.querySelector(
      `[data-tab-content-id= ${this.tabID}]`
    );
    this.tabContentID.classList.add('is-active');
  }
}
