export default class Accordion {
  constructor(element) {
    this.element = element;
    this.init();
  }

  init() {
    this.accordions = this.element.querySelectorAll('.js-header');
    this.numOpen = this.element.querySelectorAll('[data-auto-open]');

    for (let i = 0; i < this.accordions.length; i++) {
      const accordion = this.accordions[i];

      accordion.addEventListener('click', this.setActive.bind(this));

      if ('autoOpen' in accordion.dataset) {
        accordion.classList.add('is-active');
      }
      if (this.numOpen.length >= 2) {
        this.element.dataset.notClosing = '';
      }
    }
  }

  setActive(event) {
    const currentActives = this.element.querySelectorAll('.is-active');

    event.currentTarget.classList.toggle('is-active');

    if (!('notClosing' in this.element.dataset)) {
      for (let i = 0; i < currentActives.length; i++) {
        const currentActive = currentActives[i];

        currentActive.classList.remove('is-active');
      }
    }
  }
}
