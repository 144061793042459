export default class Modale {
  constructor(element) {
    this.element = element;
    this.container = this.element.querySelector('.container');
    this.options = {
      backgroundColor: '#FFF',
      color: '#000',
    };
    this.init();
  }

  init() {
    this.img = this.element.querySelector('img');
    this.img.addEventListener('click', this.showModale.bind(this));
  }

  setOptions() {
    if ('color' in this.element.dataset) {
      this.options.color = this.element.dataset.color;
    }
    if ('background' in this.element.dataset) {
      this.options.backgroundColor = this.element.dataset.background;
    }
  }

  showModale(event) {
    this.setOptions();

    this.modale = document.querySelector('.modale');
    this.modaleBtn = this.modale.querySelector('.close');
    this.container = this.modale.querySelector('.container');

    this.imgSrc = event.currentTarget.getAttribute('src');
    this.imgAlt = event.currentTarget.getAttribute('alt');

    this.modaleImg = document.createElement('img');
    this.modaleImg.setAttribute('src', this.imgSrc);
    this.modaleImg.setAttribute('alt', this.imgAlt);

    this.txt = document.createElement('p');
    this.txt.innerHTML = this.imgAlt;

    this.container.style.background = `${this.options.backgroundColor}`;
    this.modale.style.color = `${this.options.color}`;
    this.modaleBtn.style.color = `${this.options.color}`;

    this.modale.classList.add('is-active');
    document.body.style.overflow = 'hidden';
    this.modaleBtn.addEventListener('click', this.closeModale.bind(this));

    this.container.appendChild(this.modaleImg);
    this.container.appendChild(this.txt);
  }

  closeModale() {
    this.container.removeChild(this.modaleImg);
    this.container.removeChild(this.txt);

    this.modale.classList.remove('is-active');
    document.body.style.overflow = 'initial';
  }
}
