import Scrolly from './components/Scrolly';
import Carousel from './components/Carousel';
import Header from './components/Header';
import YouTube from './components/YouTube';
import Form from './components/Form';
import Tabs from './components/Tabs';
import Modale from './components/Modale';
import Accordion from './components/Accordion';
import Cursor from './components/Cursor';

export default class ComponentFactory {
  constructor() {
    this.componentList = {
      Scrolly,
      Carousel,
      Header,
      YouTube,
      Form,
      Tabs,
      Modale,
      Accordion,
      Cursor,
    };
    this.init();
  }

  init() {
    const components = document.querySelectorAll('[data-component]');

    for (let i = 0; i < components.length; i++) {
      const component = components[i];
      const componentName = component.dataset.component;

      if (componentName.includes(',')) {
        const componentNames = componentName.split(',');
        for (let i = 0; i < componentNames.length; i++) {
          const name = componentNames[i];
          console.log(name);

          if (this.componentList[name]) {
            new this.componentList[name](component);
          } else {
            console.log(`La composante ${name} n'existe pas`);
          }
        }
      } else {
        if (this.componentList[componentName]) {
          new this.componentList[componentName](component);
        } else {
          console.log(`La composante ${componentName} n'existe pas`);
        }
      }
    }
  }
}
