export default class YouTube {
  constructor(element) {
    this.element = element;

    this.videoContainer = this.element.querySelector('.js-video');
    this.poster = this.element.querySelector('.js-poster');
    this.videoId = this.element.dataset.videoId;
    this.autoplay = this.poster ? 1 : 0;
    this.playerReady = false;

    YouTube.instances.push(this);

    if (this.videoId) {
      YouTube.loadScript();
    } else {
      console.log('Vous devez spécifier un ID');
    }
  }

  /**
   * Méthode qui charge le script d'API YouTube
   */

  static loadScript() {
    if (!YouTube.scriptIsLoading) {
      YouTube.scriptIsLoading = true;

      const script = document.createElement('script');
      script.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(script);
    }
  }

  /**
   * Méthode init qui met un événement click sur le poster ou charge la vidéo
   */
  init() {
    this.initPlayer = this.initPlayer.bind(this);

    if (this.poster) {
      this.element.addEventListener('click', this.initPlayer);
    } else {
      this.initPlayer;
    }
  }

  /**
   * Méthode qui enelève l'événement de click et charge la vidéo en lui donnant les bons paramètres,
   * met un observeur sur la vidéo, met toutes les vidéos en pause quand une autre joue,
   * et met la vidéo sur pause lorsqu'elle a finie.
   */
  initPlayer(event) {
    if (event) {
      this.element.removeEventListener('click', this.initPlayer);
    }

    const variant = this.element.dataset.variant;
    if (variant == 'noControls') {
      this.controls = 0;
    } else {
      this.controls = 1;
    }

    this.player = new YT.Player(this.videoContainer, {
      height: '100%',
      width: '100%',
      videoId: this.videoId,
      playerVars: {
        rel: 0,
        autoplay: this.autoplay,
        controls: this.controls,
      },
      events: {
        onReady: () => {
          this.playerReady = true;
          const observer = new IntersectionObserver(this.watch.bind(this), {
            rootMargin: '0px 0px 0px 0px',
          });
          observer.observe(this.element);
        },
        onStateChange: (event) => {
          if (event.data == YT.PlayerState.PLAYING) {
            YouTube.pauseAll(this);
          } else if (event.data == YT.PlayerState.ENDED) {
            this.player.seekTo(0);
            this.player.pauseVideo();
          }
        },
      },
    });
  }

  /**
   * Méthode qui observe les entrées et qui les met sur pause lorsque'elles
   * ne sont plus en intersection avec l'écran
   */
  watch(entries) {
    if (this.playerReady && !entries[0].isIntersecting) {
      this.player.pauseVideo();
    }
  }

  /**
   * Méthode qui ajoute la classe is-video-ready à toutes les vidéos présentes
   */
  static initAll() {
    document.documentElement.classList.add('is-video-ready');

    for (let i = 0; i < YouTube.instances.length; i++) {
      const instance = YouTube.instances[i];
      instance.init();
    }
  }

  /**
   * Méthode qui met sur pause toutes les vidéos sauf celles passée
   * currentInstance - Reçois l'instance de la vidéo cliquée.
   */
  tim;
  static pauseAll(currentInstance) {
    for (let i = 0; i < YouTube.instances.length; i++) {
      const instance = YouTube.instances[i];
      if (instance !== currentInstance && instance.playerReady) {
        instance.player.pauseVideo();
      }
    }
  }
}

YouTube.instances = [];
YouTube.scriptIsLoading = false;
window.onYouTubeIframeAPIReady = YouTube.initAll;
