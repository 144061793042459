export default class Form {
  constructor(element) {
    this.element = element;
    this.formElements = this.element.elements;

    this.init();
  }

  /**
   * Méthode init qui donne au formulaire l'attribut noValidate
   * et qui pour chaque champ requis, valide celui-ci.
   */
  init() {
    this.element.setAttribute('noValidate', '');

    for (let i = 0; i < this.formElements.length; i++) {
      const input = this.formElements[i];

      if (input.required) {
        input.addEventListener('input', this.validateInput.bind(this));
      }
    }

    this.element.addEventListener('submit', this.onSubmit.bind(this));
  }

  /**
   * Méthode qui soumet les formulaires si ils sont validés.
   */
  onSubmit(event) {
    event.preventDefault();

    if (this.validate()) {
      console.log('Envoyé');
      this.showConfirmation();
    } else {
      console.log('Refusé');
    }
  }

  /**
   * Méthode qui retourne la valeur de la validation des formulaires si ceux-ci
   * sont requis et possèdent les bons caractères.
   */
  validate() {
    let isValid = true;

    for (let i = 0; i < this.formElements.length; i++) {
      const input = this.formElements[i];

      if (input.required && !this.validateInput(input)) {
        isValid = false;
      }
    }
    return isValid;
  }

  /**
   * Méthode qui retourne la valeur d'un champ de formulaire.
   * Si il est complet, on retire les erreurs et renvoi vrai,
   * Sinon on ajoute une erreur.
   */
  validateInput(event) {
    const input = event.currentTarget || event;

    if (input.validity.valid) {
      this.removeError(input);
    } else {
      this.addError(input);
    }

    return input.validity.valid;
  }

  /**
   * Méthode qui ajoute la classe erreur au data-input-container ou au .input le plus près.
   */
  addError(input) {
    const container =
      input.closest('[data-input-container]') || input.closest('.input');
    container.classList.add('error');
  }

  /**
   * Méthode qui enlève la classe erreur au data-input-container ou au .input le plus près.
   */
  removeError(input) {
    const container =
      input.closest('[data-input-container]') || input.closest('.input');
    container.classList.remove('error');
  }

  /**
   * Méthode qui ajoute la classe is-sent au formulaire.
   */
  showConfirmation() {
    this.element.classList.add('is-sent');
  }
}
