export default class Cursor {
  constructor(element) {
    this.cursor = element;
    this.dataset = this.cursor.dataset;
    this.init();
  }

  init() {
    this.moveCursor();
  }

  setOptions() {
    if ('color' in this.dataset) {
      this.cursor.style.background = this.dataset.color;
    } else {
      this.cursor.style.background = '#FFF';
    }
    if ('border' in this.dataset) {
      this.cursor.style.borderColor = this.dataset.border;
    } else {
      this.cursor.style.borderColor = '#000';
    }
    if ('noafter' in this.dataset) {
      this.cursor.style.setProperty('--display', 'none');
    }
  }

  moveCursor() {
    this.setOptions();
    document.addEventListener('mousemove', (e) => {
      this.cursor.style.top = `${e.pageY - 10}` + 'px';
      this.cursor.style.left = `${e.pageX - 10}` + 'px';
    });

    this.onclick();
    this.onHover();
    this.onHoverModale();
    this.onHoverVideo();
  }

  onclick() {
    document.addEventListener('click', () => {
      this.cursor.classList.add('expand');
      setTimeout(() => {
        this.cursor.classList.remove('expand');
      }, 500);
    });
  }

  onHover() {
    const links = document.querySelectorAll('.btn, .menu-item');

    links.forEach((link) => {
      link.addEventListener('mouseenter', (e) => {
        this.cursor.classList.add('active');
        this.cursor.classList.remove('unactive');
      });
      link.addEventListener('mouseleave', (e) => {
        this.cursor.classList.add('unactive');
        this.cursor.classList.remove('active');

        setTimeout(() => {
          this.cursor.classList.remove('unactive');
        }, 300);
      });
    });
  }

  onHoverModale() {
    const modales = document.querySelectorAll('[data-component="Modale"]');
    const containers = document.querySelectorAll('.modale');

    modales.forEach((modale) => {
      modale.addEventListener('mouseenter', (e) => {
        this.cursor.classList.add('active', 'hover-mod');
        this.cursor.classList.remove('unactive');
      });
      modale.addEventListener('mouseleave', (e) => {
        this.cursor.classList.add('unactive');
        this.cursor.classList.remove('active', 'hover-mod');

        setTimeout(() => {
          this.cursor.classList.remove('unactive');
        }, 300);
      });
    });

    containers.forEach((container) => {
      container.addEventListener('mouseenter', (e) => {
        this.cursor.classList.remove('active', 'hover-mod');
      });
    });
  }
  onHoverVideo() {
    const videos = document.querySelectorAll('.video');

    videos.forEach((video) => {
      video.addEventListener('mouseenter', (e) => {
        this.cursor.classList.add('active', 'hover-mod');
        this.cursor.classList.remove('unactive');
      });
      video.addEventListener('mouseleave', (e) => {
        this.cursor.classList.add('unactive');
        this.cursor.classList.remove('active', 'hover-mod');
      });
    });
  }
}
